// Private Configuration Constants

const HTTPS_ENDPOINT = 'https://creditmanager-dev.synergipartners.com/api';
const APPLICATION_KEY = '';

export const environment = {
  production: false,
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 9999,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'synergi-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'synergi-themeradius',
  impactEndDate: '9/30/2021',
  // Should match the Inactivity Expiration in the Auth0 Application
  idleTimeout: 1800,
  appInsights: {
    instrumentationKey: '20161d9d-ade7-41d9-842b-9948cbdd1b7c',
  },
  auth: {
    clientID: 'rdP84haoqMsTi7S0arR4wAhkhmAkfcVl',
    domain: 'login-dev.synergipartners.com',
    audience: 'sypa-dev-credit-manager-auth',
    auth0rulesclaimsnamespace: 'https://synergipartners.com/roles',
    logoutUrl: 'https://creditmanager-dev.synergipartners.com',
  },
  services: {
    subscriptionkey: '',
  },
  api: {
    base: HTTPS_ENDPOINT,
    calculateEmployeeRetentionCredit: `${HTTPS_ENDPOINT}/calculateEmployeeRetentionCredit`,
    calculateHR748Comparison: `${HTTPS_ENDPOINT}/calculateHR748Comparison`,
    calculatePayPeriod: `${HTTPS_ENDPOINT}/calculatePayPeriod`,

    deleteCreditPackage: `${HTTPS_ENDPOINT}/deleteCreditPackage`,
    deleteImpactPeriod: `${HTTPS_ENDPOINT}/deleteImpactPeriod`,
    deleteLeavePeriod: ``,
    deleteLoanPeriod: `${HTTPS_ENDPOINT}/deleteLoanPeriod`,

    ensureClients: `${HTTPS_ENDPOINT}/ensureClients`,
    ensureEmployees: `${HTTPS_ENDPOINT}/ensureEmployees`,
    ensureImpactPeriod: `${HTTPS_ENDPOINT}/ensureImpactPeriod`,
    ensureLeavePeriods: `${HTTPS_ENDPOINT}/ensureLeavePeriods`,
    ensureLoanPeriod: `${HTTPS_ENDPOINT}/ensureLoanPeriod`,
    upsertLoanPeriod: `${HTTPS_ENDPOINT}/upsertLoanPeriod`,
    ensureLocations: `${HTTPS_ENDPOINT}/ensureLocations`,
    ensurePayroll: `${HTTPS_ENDPOINT}/ensurePayroll`,

    ensureStatusEntries: `${HTTPS_ENDPOINT}/ensureStatusEntries`,

    filterCU: ``,

    getAllStatusTypes: `${HTTPS_ENDPOINT}/getAllStatusTypes`,
    getAllStatusValues: `${HTTPS_ENDPOINT}/getAllStatusValues`,
    getAllClientGroups: `${HTTPS_ENDPOINT}/getAllClientGroups`,
    getClientGroupClients: `${HTTPS_ENDPOINT}/getClientGroupClients`,
    deleteClientGroup: `${HTTPS_ENDPOINT}/deleteClientGroup`,
    getAllClientGroupsWithAllClients: `${HTTPS_ENDPOINT}/getAllClientGroupsWithAllClients`,
    getClientsByClientGroupId: `${HTTPS_ENDPOINT}/getClientsByClientGroupId`,
    getClientGroupInfoByClientId: `${HTTPS_ENDPOINT}/getClientGroupInfoByClientId`,
    upsertClientGroup: `${HTTPS_ENDPOINT}/upsertClientGroup`,
    getAllTags: `${HTTPS_ENDPOINT}/getAllTags`,
    getClient: `${HTTPS_ENDPOINT}/getClient`,
    getClients: `${HTTPS_ENDPOINT}/getClients`,

    getConfiguration: `${HTTPS_ENDPOINT}/getConfiguration`,

    getCreditPackages: `${HTTPS_ENDPOINT}/getCreditPackages`,
    getCreditStatusReport: `${HTTPS_ENDPOINT}/getCreditStatusReport`,
    getCreditSummaryForClient: `${HTTPS_ENDPOINT}/getCreditSummaryForClient`,
    getCreditSummaryForEmployee: `${HTTPS_ENDPOINT}/getCreditSummaryForEmployee`,
    getCreditSummaryForLocation: `${HTTPS_ENDPOINT}/getCreditSummaryForLocation`,
    getEmployee: ``,
    getImpactPeriod: `${HTTPS_ENDPOINT}/getImpactPeriod`,
    getLeavePeriod: `${HTTPS_ENDPOINT}/getLeavePeriod`,
    getLoanPeriod: `${HTTPS_ENDPOINT}/getLoanPeriod`,
    getLocation: `${HTTPS_ENDPOINT}/getLocation`,
    getCifNotification: `${HTTPS_ENDPOINT}/getCifNotification`,
    getNotifications: `${HTTPS_ENDPOINT}/getNotifications`,
    getPayCodeMapping: `${HTTPS_ENDPOINT}/getPayCodeMapping`,
    getStatusEntriesForClient: `${HTTPS_ENDPOINT}/getStatusEntriesForClient`,

    importFFCRAFile: `${HTTPS_ENDPOINT}/importFFCRAFile`,
    importInsuranceCostFile: `${HTTPS_ENDPOINT}/importInsuranceCostFile`,
    importLeavePeriod: ``,
    importLocationFile: `${HTTPS_ENDPOINT}/importLocationFile`,
    importPayrollFile: `${HTTPS_ENDPOINT}/importPayrollFile`,
    insertCreditPackage: `${HTTPS_ENDPOINT}/insertCreditPackage`,

    modifyCU: ``,

    saveHR748ComparisonWorkbook: `${HTTPS_ENDPOINT}/saveHR748ComparisonWorkbook`,
    ScrapeUrl: ``,
    sendCif: `${HTTPS_ENDPOINT}/sendCif`,

    updateCreditPackage: `${HTTPS_ENDPOINT}/updateCreditPackage`,
    updatePayCodeMapping: `${HTTPS_ENDPOINT}/updatePayCodeMapping`,

    getClientIntakeFormData: `${HTTPS_ENDPOINT}/getClientIntakeFormData`,
    getClientIntakeFormDataV2: `${HTTPS_ENDPOINT}/getClientIntakeFormDataCifV2`,
    saveCifData: `${HTTPS_ENDPOINT}/saveCifData`,
    excludeEmployees: `${HTTPS_ENDPOINT}/excludeEmployees`,
    getEmployeesByClient: `${HTTPS_ENDPOINT}/getEmployeesByClient`,
    getExcludedEmployeesByClient: `${HTTPS_ENDPOINT}/getExcludedEmployeesByClient`,
    upsertEmployeeExclusions: `${HTTPS_ENDPOINT}/upsertEmployeeExclusions`,
  },
  // Note: This key is populated at deployment time (see: inject-api-function-key-secret.ps1)
  apiKey: '/AYKRcjqQrOXoNnbVoGJU4/3sgrT6Zlh3qGLkMGaHk3UNcm79/n/aw==',
  version: '1.0.62607+sha.29020b7',
};
